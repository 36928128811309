<template>
    <div class="card card-f-h flex flex-col">
        <el-table v-if="autogiroFilesData.length > 0" :data="autogiroFilesData">
            <el-table-column :label="$t('economy.amount')" sortable>
                <template slot-scope="scope">
                    {{ intToMoneyFormat(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('economy.status')" sortable>
                <template slot-scope="scope">
                    <p>{{ $t(`economy.${scope.row.status}`) }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="updated_at" :label="$t('economy.status_changed')" sortable />
            <el-table-column prop="created_at" :label="$t('economy.created_at')" sortable />
            <el-table-column width="100">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('economy.download')" placement="top">
                            <el-button type="primary" size="mini" @click="handleDownload(scope.row.id)">
                                <fa-icon :icon="['fas', 'download']" fixed-width />
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                            <el-button type="danger" size="mini" @click="handleDelete(scope.row.id)">
                                <fa-icon :icon="['fas', 'trash']" fixed-width />
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            noDataInformation: false,
            autogiroFilesData: [],
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_autogiro_files');
    },

    created() {
        this.$store.commit('setPageTitle', 'Autogiro');
        this.$store.commit('setActiveMenuItem', '6-3');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/economy/autogiro_files`);
            this.autogiroFilesData = Object.freeze(data);
            this.noDataInformation = data.length;
            this.$wait.end('loading');
            this.$wait.end('loading.initial_autogiro_files');
        },

        async handleChangeStatus(uuid) {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/economy/autogiro_files/${uuid}/status_to_sent`);
            this.$notify.success({ title: 'Success' });
            this.getAll();
        },

        handleDownload(id) {
            window.open(`${process.env.VUE_APP_CORE_URL}autogiro_files/${id}/download`);
        },

        handleDelete(id) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/economy/autogiro_files/${id}`);
                            this.$notify.success({ title: 'Success' });
                            this.getAll();
                        } catch {
                            return;
                        }

                        done();
                        // @Filip
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },
    },
};
</script>
